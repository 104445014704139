<template>
<div class="border-bottom p-1" v-if="data==null">
        <a href="/" class="me-2">Start</a>/
        <router-link to="/protocols" class="me-2">Protokoły</router-link>/
        <span class="me-2">Protokół</span>
    </div>
    <h1 class="mt-4">Protokół {{head.nr_pelny}}</h1>
    <p v-html="getStatus(head)"></p>
    <div class="w-100 text-end">
        <button v-if="!isEditMode && !isEditRemarks" @click="setEditMode(true)" class="btn btn-sm btn-outline-primary mb-3">
        Edytuj
        </button>
        <button v-if="isEditMode" @click="saveDocument()" class="btn btn-sm btn-outline-danger mb-3 me-3">Usuń</button>
        <button v-if="isEditMode || isEditRemarks" @click="abortChanges()" class="btn btn-sm btn-outline-success mb-3 me-3">Anuluj</button>
        <button v-if="isEditMode || isEditRemarks" @click="saveDocument()" class="btn btn-sm btn-outline-primary mb-3">
        Zapisz 
        </button>
    </div>

    <form>          
      <div class="row">
        <div class="mb-3 col-md-6">
            <label for="podtytul_prot" class="form-label">Tytuł protokołu</label>
            <input type="text" id="podtytul_prot" class="form-control" v-model="head.podtytul_prot" :readonly="!isEditMode" :disabled="!isEditMode">
            

            <label for="uwagi" class="form-label">Uwagi</label>
           <textarea max-length="500" id="uwagi" class="form-control" v-model="head.uwagi" :readonly="!isEditMode && !isEditRemarks" :disabled="!isEditMode && !isEditRemarks"></textarea> 
            <p> </p>
            <label for="data_dokumentu" class="form-label">Dokument z dnia</label>
            <input type="date" id="data_dokumentu" class="form-control" v-model="head.data_dokumentu" :readonly="!isEditMode" :disabled="!isEditMode">        
        </div>
        <div class="mb-3 col-md-6 ">        
           
        </div>
      </div>      
    </form>

    <div id="entries" style="font-size:0.9em">
        <div class="row border-bottom py-1"  v-for="(entry, index) in head.protokoly_akcesje" :key="entry.id_akc">
            <div class="col-md-3" title="Nr akcesji&#10; tytuł">
              <label for="prot_akc" class="form-label">{{index+1}}. Nr akcesji</label><br/>
              <input type="text" id="prot_akc"  v-model="entry.prot_akc" class="form-control" @blur="checkEntryOnExit(entry)" :readonly="!isEditMode" :disabled="!isEditMode"/>
              
              <input type="hidden" id="id_akc"  v-model="entry.id_akc"/>             
              <small>{{entry.tytul_woluminu}}</small>
            </div>
            <div class='col-md-1'>
              <label v-if="isEditMode" class="form-label">Wybierz</label><br/>
              <span v-if="isEditMode" class="btn btn-sm btn-outline-primary ms-3 hand" @click="showSelectEntry(entry)">W</span>  
            </div>
            <div class="col-md-1 text-end">
              <label for="liczba_woluminow" class="form-label" title = "Ilość wolumentów">Il. vol</label>
              <input type="number" id="liczba_woluminow" class="form-control" v-model="entry.liczba_woluminow" :readonly="!isEditMode" :disabled="!isEditMode"/>             
            </div>
            <div class="col-md-2 text-end">
              <label for="uwagi" class="form-label">Sygnatura</label>
              <input type="text" id="sygnatura" class="form-control" v-model="entry.sygnatura" :readonly="!canEditSygnature()" :disabled="!canEditSygnature()"/>
            </div>
            <div class="col-md-3 text-end">
              <label for="uwagi" class="form-label">Uwagi</label>
              <input type="text" id="uwagi" class="form-control" v-model="entry.uwagi" :readonly="!isEditMode" :disabled="!isEditMode"/>             
            </div>
            <div class="col-md-2 text-end">
              <span class="text-primary hand" v-if="isEditMode" @click="deleteProtocolLine(entry)">usuń</span><br/>
              <small>Data operacji: {{entry.data_operacji}}</small>
              
            </div>            
        </div>
        <div class="w-100 text-end">
          Ilośc vol: <b>{{head.suma_woluminow}}</b><br/>
          Ilośc dzieł: <b>{{head.ilosc_pozycji}}</b>
          
        </div>
        <div class="w-100 text-end" v-if="isEditMode">
          <span class="btn btn-sm btn-outline-primary my-2" @click="newEntry()">dodaj pozycję</span>
        </div>        
    </div>


    <div class="row my-3" v-if="head.id_prot>0">
      <div class="col-6  text-end">GROM</div>
      <div class="col-6"><span v-if="!head.podpis_grm && getRola()=='grm'" class="text-success hand"  @click="setStatus('grm')">[ Podpisz ]</span> {{head.data_grm}} <i>{{head.podpis_grm}}</i></div>
      <div class='col-12'><hr/></div>

      <div class="col-6  text-end">GROM &gt; OPR</div>
      <div class="col-6"><span v-if="!head.podpis_opr && getRola()=='opr'" class="text-success hand"  @click="setStatus('opr')">[ Podpisz ]</span> {{head.data_opr}} <i>{{head.podpis_opr}}</i></div>
      <div class='col-12'><hr/></div>

      <div class="col-6  text-end">OPR @ KAT</div>
      <div class="col-6"><span v-if="!head.podpis_kat && getRola()=='opr'" class="text-success hand"  @click="setStatus('kat')">[ Podpisz ]</span> {{head.data_kat}} <i>{{head.podpis_kat}}</i></div>
<div class='col-12'><hr/></div>

      <div class="col-6  text-end">OPR @ INW</div>
      <div class="col-6"><span v-if="!head.podpis_inw && getRola()=='opr'" class="text-success hand"  @click="setStatus('inw')">[ Podpisz ]</span> {{head.data_inw}} <i>{{head.podpis_inw}}</i></div>
<div class='col-12'><hr/></div>

      <div class="col-6  text-end">OPR &gt; MAG</div>
      <div class="col-6"><span v-if="!head.podpis_mag && getRola()=='mag'" class="text-success hand"  @click="setStatus('mag')">[ Podpisz ]</span> {{head.data_mag}} <i>{{head.podpis_mag}}</i></div>    
  <div class='col-12'><hr/></div>

      <div class="col-6  text-end">PIU &gt; GROM</div>
      <div class="col-6"><span v-if="!head.podpis_piu && head.podpis_grm && (getRola()=='grm' || getRola()=='piu')" class="text-success hand"  @click="setStatus('piu')">[ Podpisz ]</span> {{head.data_piu}} <i>{{head.podpis_piu}}</i></div>
      
    </div>


    <div class="overlay" v-if="isShowFindEntry">
      <div class="popup">
        <div class="w-100 text-end">
          <button @click="closePopupKnt" class="btn btn-sm btn-outline-primary">
            Zamknij
          </button>
        </div>
          <EntryList :data="objectToPass" />
      </div>
    </div>
    
</template>
<script>


import {fetchData} from "@/services/FetchMachine";
import {showToast, getProtocolStatus, customYesNo} from "@/services/Dialogs" //customYesNo
//import AjaxCombo from "../components/AjaxCombo.vue"
import EntryList from '../components/EntryList.vue'

export default {
  name: "ProtocolDetails",
  data() {
    return {
        objectToPass: null,
        isShowFindEntry : false,
        isEditMode: false,
        isEditRemarks : false,
        deletedItems: [],
        currentProtEntry : null,//line
        head: {},        
    };
  },

  components: {
    EntryList
  },
  
  created() {
    if (this.$route.params.id>0)
      this.fetchDocument(this.$route.params.id);
    else 
      this.newDocument();
  },

  methods: {
    setEditMode(v) {
      if (localStorage.getItem("r")==="grm")
        this.isEditMode = v;
      else 
        this.isEditRemarks = v;
    },

    getRola() {
      return localStorage.getItem("r");
    },

    canEditSygnature() {
      let canEdit = false;
      if (this.isEditMode || this.isEditRemarks) canEdit = true;            
      if (this.getRola()!='opr') canEdit = false;
      return canEdit;
    },

    areEntriesDuplicated(protocolLine=null) {
      if (protocolLine==null) {        
        protocolLine = this.currentProtEntry;
      }
      setTimeout(()=>{
        console.log("xxx");
        let total = this.head.protokoly_akcesje.length;
        let uniq = new Set(this.head.protokoly_akcesje.map(item => item.prot_akc)).size;
        console.log(total, uniq);
        if (total>0 && uniq>0 && total!=uniq) {
          protocolLine.id_akc = "";
          protocolLine.prot_akc = "";
          protocolLine.tytul_woluminu = "";
          showToast("Uwaga! Wprowadzono tę samą akcesję. Proszę poprawić dokument. Numer zostanie usunięty!", false);
        }
    },200);

    },

    closePopupKnt() {
      this.isShowFindEntry = false;
      this.areEntriesDuplicated();
    },

    setStatus(newStatus) {
      fetchData(
        "?section=protocol&action=set-status", {"id": this.head.id_prot, status:newStatus},
        (res) => {          
          console.log(res);
          this.fetchDocument(this.head.id_prot);
          
        }, ()=>{
          showToast("Nie można podpisać protokołu. Upewnij się, że masz uprawnienia oraz złożono wcześniej wymagane podpisy.", false);
        } );    
    },

    deleteProtocolLine(protocolLine) {
      customYesNo("Potwierdź czy chcesz usunąć pozycję",()=>{        
        console.log(protocolLine);
        if (protocolLine.id_prot_akc!=null) this.deletedItems.push(protocolLine.id_prot_akc);
        this.head.protokoly_akcesje = this.head.protokoly_akcesje.filter(item => item !== protocolLine);        
      });
    },

    copyFromEntryToProtocol(entry, protocolLine) {
      if (protocolLine.id_prot==null) protocolLine.id_prot = -Date.now();
      protocolLine.id_akc = entry.id_akc;
      protocolLine.prot_akc = entry.prot_akc;
      protocolLine.tytul_woluminu = entry.tytul_woluminu;
      if (protocolLine.liczba_woluminow ==null) protocolLine.liczba_woluminow = entry.liczba_woluminow;      
    },

    showSelectEntry(entry) {
        this.currentProtEntry = entry;
        this.objectToPass = {action:"select-without-protocol", entry: entry,  callback: (c)=>{ 
            this.copyFromEntryToProtocol(c, this.currentProtEntry);
            this.isShowFindEntry = false;
            this.areEntriesDuplicated();
        }};
        this.isShowFindEntry = true;
    },

    newEntry() {      
      fetchData(
        "?section=protocol&action=empty-line", null,
        (empty) => {
          empty.prot_akc = new Date().getFullYear() + "-K";
          empty.id_prot = this.head.id_prot;          
          this.head.protokoly_akcesje.push(empty);
        } );      
    },
//2024-D-1015
    checkEntryOnExit(protocolLine)  {
      console.log(protocolLine);
      if (protocolLine.prot_akc==null || protocolLine.prot_akc=="") return;
      fetchData(
        "?section=protocol&action=check-entry", {entry : protocolLine},
        (data) => {
          //if (data) {
            this.copyFromEntryToProtocol(data, protocolLine);
            this.areEntriesDuplicated(protocolLine);
        //  }
        },(x)=>{ 
          console.log(x);
          protocolLine.id_akc = '';
          showToast("Błedny numer akcesji lub akcesja znajduje się w innym protokole")
          } );
    },

    newDocument() {
      fetchData("?section=protocol&action=empty",{mode:"k"},
       res=>{
        this.head = res;
        this.isEditMode = true;
       });
    },

    getStatus(doc) {
      return getProtocolStatus(doc);
    },

    abortChanges() {
      this.setEditMode(false);
      this.fetchDocument(this.head.id_fv);
    },

    saveDocument() {      
      this.head["_deleted"] = this.deletedItems;
      fetchData("?section=protocol&action=save",{mode:"k", protocol: this.head},
        (res)=>{
          console.log(res);
          this.head=res;          
          showToast("Zapisano pomyślnie.", true); 
          this.setEditMode(false);
        },
        (res)=>{
          if (res==null) res = "";
          console.log(res);
          if (String(res).includes("REQ"))
            showToast("Błąd zapisu, proszę wybrać protokół", false);
          else
            showToast(res, false);
        },        
      )
    },    

    fetchDocument(id = this.$route.params.id) {
      fetchData(
        "?section=protocol&action=details",
        { mode:"k",id: id },
        (res) => {
          this.head = res;
          if (this.head.platnik_vat == null) this.head.platnik_vat=0;
        },
        () => {            
            showToast("Błąd pobierania danych", false);
          //document.getElementById("content").innerHtml = "X";
          //$("#clients").html("Błąd");
        }
      );
    },

  },
};
</script>

			
	