<template>
  <div class="border-bottom p-1" v-if="data == null">
    <router-link to="/" class="me-2">Start</router-link>/
    <router-link to="/clients" class="me-2">Kontrahenci</router-link>/
    <span class="me-2">Kontrahent</span>
  </div>
  <h1 class="mt-4">Kontrahent</h1>

  <div class="w-100 text-end">
    <button v-if="!isEditMode" @click="setEditMode(true)" class="btn btn-sm btn-outline-primary mb-3">
      Edytuj
    </button>
    <button v-if="isEditMode" @click="deleteClient()" class="btn btn-sm btn-outline-danger mb-3 me-3">Usuń</button>
    <button v-if="isEditMode" @click="abortChanges()" class="btn btn-sm btn-outline-success mb-3 me-3">Anuluj</button>
    <button v-if="isEditMode" @click="saveClient()" class="btn btn-sm btn-outline-primary mb-3">Zapisz</button>
  </div>
  <form>
    <div class="mb-3">
      <label for="nazwa_wystawcy" class="form-label">Nazwa</label>
      <input type="text" id="nazwa_wystawcy" class="form-control" v-model="client.nazwa_wystawcy"
        :readonly="!isEditMode" :disabled="!isEditMode">
    </div>
    <div class="mb-3">
      <label for="adres_wystawcy" class="form-label">Adres</label>
      <input type="text" id="adres_wystawcy" class="form-control" v-model="client.adres_wystawcy"
        :readonly="!isEditMode" :disabled="!isEditMode">
    </div>
    <div class="mb-3">
      <label for="adres_wystawcy_cd" class="form-label">Kod pocztowy / miasto</label>
      <input type="text" id="adres_wystawcy_cd" class="form-control" v-model="client.adres_wystawcy_cd"
        :readonly="!isEditMode" :disabled="!isEditMode">
    </div>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label for="nip" class="form-label">NIP</label>
        <input type="text" id="nip" class="form-control" v-model="client.nip" :readonly="!isEditMode"
          :disabled="!isEditMode">
      </div>
      <div class="mb-3 col-md-6">
        <label for="platnik_vat" class="form-label">Płatnik VAT</label>
        <select type="text" id="nip" class="form-select" v-model="client.platnik_vat" :readonly="!isEditMode"
          :disabled="!isEditMode">
          <option value="0">Nie</option>
          <option value="1">Tak</option>
        </select>
      </div>
    </div>
    <div class="mb-3">
      <label for="uwagi" class="form-label">Uwagi</label>
      <textarea id="uwagi" class="form-control" v-model="client.uwagi" :readonly="!isEditMode"
        :disabled="!isEditMode"></textarea>
    </div>
  </form>
  <div>
    <h1>Historia</h1>
    <div class="row border rounded p-3 bg-light my-4">
      <div class="col-md-4">
        Filtruj historię:
        Rok transakcji
        <select class="form-select" v-model="selectedYear">
          <option v-for="y in years" :key="y">{{ y }}</option>
        </select>
      </div>
      <div class="col-md-4">
        Miesiąc transakcji
        <select class="form-select" v-model="selectedMonth">
          <option value="">-dowolny-</option>
          <option value="1">styczeń</option>
          <option value="2">luty</option>
          <option value="3">marzec</option>
          <option value="4">kwiecień</option>
          <option value="5">maj</option>
          <option value="6">czerwiec</option>
          <option value="7">lipiec</option>
          <option value="8">sierpień</option>
          <option value="9">wrzesień</option>
          <option value="10">październik</option>
          <option value="11">listopad</option>
          <option value="12">grudzień</option>
        </select>
      </div>
      <div class="col-md-4">        
        Typ
        <select class="form-select" v-model="selectedKWD">
          <option value="">-dowolny-</option>
          <option value="k">kupno</option>
          <option value="w">wymiana</option>
          <option value="d">dary</option>
        </select>
      </div>
    </div>
    <h2>Dokumenty nabycia</h2>

    <div class="row fw-bold">
      <div class="col-md text-center">Data</div>
      <div class="col-md">Typ</div>
      <div class="col-md">Dokument</div>
      <div class="col-md text-end">Brutto</div>
    </div>
    <div v-for="(entry, index) in docs" :key="entry.nabycie_id_fvat">
      <div class="row border-bottom" v-if="canDisplayDoc(entry)">
        <div class="col-12 fw-bold" v-if="(index === 0 || docs[index - 1].rok_mc !== entry.rok_mc)">Rok miesiąc {{
          entry.rok_mc }}:</div>
        <div class="col-md text-center" >
          {{ entry.data_dokumentu }}
        </div>
        <div class="col-md">
          {{ code2name(entry.kwd) }}
        </div>
        <div class="col-md">
          <router-link v-if="entry.nabycie_id_fvat > 0"
            :to="{ name: 'DocumentDetails', params: { kwd: entry.kwd, id: entry.nabycie_id_fvat } }">
            {{ entry.dokument_obcy }} </router-link>
        </div>
        <div class="col-md text-end">{{ entry.wartosc_brutto }}</div>
        
      </div>
    </div>

    <!-- Sum of wartosc_brutto -->
<div class="row font-weight-bold mt-3">
  <div class="col-md text-end">
    Suma prezentowanych dokumentów: {{ totalBrutto }}
  </div>
</div>

    <!--<div class="row">
      <div class="col-12 text-end">
        Łącznie brutto: {{ sumDocs().toFixed(2) }}</div>
    </div> -->
  

  </div>
  <h2>Akcesje</h2>

  
  <div id="entries" style="font-size:1em">
    <div  v-for="entry in entries" :key="entry.id_akc">
      <div class="row border-bottom" v-if="canDisplayEntry(entry)">
        <div class="col-md-8">{{ entry.tytul_woluminu }}<br /><i>{{ entry.autor_woluminu }}</i></div>
        <div class="col-md-4">Data akcesji {{ entry.data }}<br />
          <router-link :to="{ name: 'EntryDetails', params: { id: entry.id_akc } }">Nr akcesji: {{ entry.prot_akc
            }}</router-link>
          <br />
          <router-link v-if="entry.nabycie_id_fvat > 0"
            :to="{ name: 'DocumentDetails', params: { kwd: entry.kwd, id: entry.nabycie_id_fvat } }">
            Dokument nabycia {{ entry.dokument_obcy }}<br /></router-link>
          Cena brutto: {{ entry.cena_jednostkowa_brutto }}
        </div>
      </div>  
    </div>

  </div>


</template>
<script>
import { fetchData } from "@/services/FetchMachine";
import { showToast, customYesNo, getCodeToName } from "@/services/Dialogs" //customYesNo

export default {
  name: "ClientDetails",
  data() {
    return {
      isEditMode: false,
      selectedYear: "-dowolny-",
      selectedMonth : "",
      selectedKWD : "",
      client: {},
      years: [],
      entries: [],
      docs: []
    };
  },



  created() {
    const currentYear = new Date().getFullYear();
    this.years.push("-dowolny-");
    for (var a = currentYear; a > 2009; a--) {
      this.years.push(a);
    }
    this.fetchDetails();
    this.fetchEntries();
  },
  computed: {
  totalBrutto() {
    return this.getDocs()
      .filter(entry => this.canDisplayDoc(entry)) // Only include displayed entries
      .reduce((sum, entry) => sum + (parseFloat(entry.wartosc_brutto) || 0), 0) // Ensure numeric values
      .toFixed(2); // Format as a number with two decimal places
  }
},
//async mounted() {},
  methods: {
    setEditMode(v) {
      this.isEditMode = v;
    },

    code2name(c) {      
      return getCodeToName(c);      
    },

    canDisplayDoc(doc) {
      if (this.selectedMonth<1 && this.selectedYear<0 && this.selectedKWD=="") return true;
      let can = true;
      let rokMc = doc.rok_mc.split("-");
      if (rokMc.length!=2) return true;

      
      //console.log(this.selectedMonth, docMc[1]);
      if (this.selectedMonth>0 && this.selectedMonth!=Number(rokMc[1])) can=false;
      if (this.selectedYear>0 && this.selectedYear!=Number(rokMc[0])) can=false;      
      if (this.selectedKWD!="" && this.selectedKWD != doc.kwd.toLowerCase())  can = false;
      
      return can;      
    },
    canDisplayEntry(entry) {
      if (this.selectedMonth<1 && this.selectedYear<0 && this.selectedKWD=="") return true;
      let can = true;
      let rokMc = entry.data_dokumentu.split("-");
      if (this.selectedMonth>0 && this.selectedMonth!=Number(rokMc[1])) can=false;
      if (this.selectedYear>0 && this.selectedYear!=Number(rokMc[0])) can=false;
      if (this.selectedKWD!="" && this.selectedKWD != entry.kwd.toLowerCase())  can = false;
      return can;      
    },

    getDocs() {
      let res = Array.from(
        new Map(
          this.entries.map(item => [
            `${item.nabycie_id_fvat}_${item.kwd}_${item.wartosc_netto}_${item.wartosc_brutto}_${item.nazwa_wystawcy}_${item.dokument_obcy}_${item.data_dokumentu}`,
            {
              nabycie_id_fvat: item.nabycie_id_fvat,
              kwd: item.kwd,
              wartosc_netto: item.wartosc_netto,
              wartosc_brutto: item.wartosc_brutto,
              nazwa_wystawcy: item.nazwa_wystawcy,
              dokument_obcy: item.dokument_obcy,
              data_dokumentu: item.data_dokumentu,
              rok_mc: item.data_dokumentu.substring(0, 7)
            }
          ])
        ).values());

        
      return res;
    },
    sumDocs() {
      let x = this.getDocs();
      let sum = 0;
      x.forEach(o => {
        sum += Number(o.wartosc_brutto);
      });
      return sum;
    },
    saveClient() {
      fetchData(
        "?section=client&action=save",
        { client: this.client },
        (res) => {
          this.client = res;
          showToast("Dane zapisane", true);
        },
        () => {
          showToast("Błąd zapisu", false);
        }
      );
    },

    abortChanges() {
      this.setEditMode(false);
      this.fetchDetails(this.client.client);
    },
    deleteClient() {      
      customYesNo("Potwierdź czy chcesz usunąć klienta?", () => {
        //console.log("CLOENT",this.client.id_knt);
        fetchData(          
          "?section=client&action=delete", { id: this.client.id_knt },
          (res) => {
            console.log(res);
            showToast("Karta klienta usunięta.", true, () => { location.href = "/clients"; });
            
          },
          (err) => {
            console.log(err);
            showToast(err, false, () => { location.reload(); });
          }
        );
      });
    },

    fetchDetails(id = this.$route.params.id) {
      var action = "details";
      console.log("id", id);

      if (id == null || id == "0") {
        id = 0;
        this.isEditMode = true;
        action = "empty";
      }
      fetchData(
        "?section=client&action=" + action,
        { id: id },
        (res) => {
          this.client = res;
          
        },
        () => {
          showToast("Błąd pobierania danych", false);
        }
      );
    },

    fetchEntries() {
      var id = this.$route.params.id;
      if (id < 1) return;
      fetchData(
        "?section=entry&action=entries-for-client",
        { kntId: this.$route.params.id },
        (res) => {          
          this.entries = res;
          this.docs = this.getDocs();          
        },
        () => { }
      );

    }

  },
};
</script>
