/*export function customAlert(text) {

}
*/
export function customYesNo(text, onYes, onNo=null) {
    var css = 'bg-light';
    var popup = document.createElement("div");
    var tmp = "id" + Date.now();
    popup.classList.add(css, 'border','border-primary', 'rounded', 'p-3', 'text-center');
    console.log("POP",popup);
    popup.style.cssText = "z-index:9999; left:0; right:0;margin:0 auto; width:50%; min-width:250px;position:fixed; top: 70px;";
    popup.innerHTML = text + `<br/><br/><span id="no${tmp}" class="btn btn-outline-primary me-2">Nie</span> <span id="yes${tmp}" class="ms-2 btn btn-primary">Tak</span>`;
    console.log(onYes, onNo);
    document.getElementsByTagName("body")[0].appendChild(popup);
    document.querySelector(`#no${tmp}`).addEventListener('click', ()=>{
        popup.remove();
        if (onNo) onNo();
    });
    document.querySelector(`#yes${tmp}`).addEventListener('click', ()=>{
        popup.remove();
        if (onYes) onYes();
    });
}

export function showToast(text, positive, done = null) {
    var css = 'bg-success';
    if (positive != true) css = 'bg-danger';
    var popup = document.createElement("div");
    popup.classList.add(css, 'text-light', 'p-5', 'text-center');
    console.log("POP",popup);
    popup.style.cssText = "z-index:9999; left:0; right:0;margin:0 auto; width:50%; min-width:250px;position:fixed; bottom: 50px;";
    popup.innerHTML = text;
    document.getElementsByTagName("body")[0].appendChild(popup);
    setTimeout(() => {
        popup.remove();
        if (done != null) done();
    }, 3500);
    //console.log(done);
}

export function getCodeToName(code) {
    if (code==null) return "";
    code = code.toLowerCase();
    if (code=="k") return "kupno";
    if (code=="w") return "wymiana";
    if (code=="d") return "dary";
    return code;
}
export function getProtocolStatus(doc) {
    var a = "font-weight:normal; color:#40c030";
    var i = "color:#a0a0a0;font-size:0.8em";
    var p = `<span style='${doc.podpis_grm? a : i}'>GRM</span> 
        <span style='${doc.podpis_opr? a : i}'>OPR</span> 
        <span style='${doc.podpis_kat? a : i}'>KAT</span> 
        <span style='${doc.podpis_inw? a : i}'>INW</span>         
        <span style='${doc.podpis_mag ? a : i}'>MAG</span>
        <span style='${doc.podpis_piu? a : i}'>PIU</span> 
        `;
        
    return p;
    /*<span v-if="doc.podpis_grm">GRM | </span>
    <span v-if="doc.podpis_opr">OPR | </span>
    <span v-if="doc.podpis_piu">PIU | </span>
    <span v-if="doc.podpis_mag">MAG | </span>*/

}
